import { nanoid } from 'nanoid'


export default class CitiesAutocomplete {
  constructor(form) {
    this.form = form
    this.postcodeInput = form.querySelector('[data-input-postcode]')
    this.cityInput = form.querySelector('[data-input-city]')
    this.autocompleteList = null
    this.autocompleteItems = []
    this.autocompleteId = nanoid(6)

    this.init()
  }

  addAccessibilityAttributes() {
    this.postcodeInput.setAttribute('role', 'combobox')
    this.postcodeInput.setAttribute('aria-expanded', 'false')
    this.postcodeInput.setAttribute('aria-autocomplete', 'list')
    this.postcodeInput.setAttribute('aria-controls', `searchBlock-${this.autocompleteId}`)
  }

  createAutocompleteList() {
    this.autocompleteList = document.createElement('ul')
    this.autocompleteList.classList.add('Form-autocomplete')
    // Attributes for accessibility
    this.autocompleteList.setAttribute('role', 'listbox')
    this.autocompleteList.setAttribute('id', `searchBlock-${this.autocompleteId}`)

    this.postcodeInput.parentNode.append(this.autocompleteList)
  }

  fillAutocompleteList(results) {
    results.forEach(result => {
      const listItem = document.createElement('li')
      const button = document.createElement('button')

      button.classList.add('Form-autocompleteButton')
      button.setAttribute('type', 'button')
      button.innerText = result.value ? `${result.value} - ${result.city}` : result.label

      if (!result.value) {
        button.disabled = true;
      }

      listItem.classList.add('Form-autocompleteItem')
      listItem.append(button)
      this.autocompleteList.append(listItem)

      this.selectPostcode(result, button)
    })
  }

  dumpAutocomplete() {
    this.autocompleteList.innerHTML = ""
  }

  selectPostcode(result, button) {
    button.addEventListener('click', () => {
      this.postcodeInput.value = result.value
      this.cityInput.value = result.city
      this.cityInput.dispatchEvent(new Event('input'));

      this.dumpAutocomplete()

      this.postcodeInput.focus()
    })
  }

  fetchCities(event) {
    const baseUrl = window.location.origin
    const postcode = event.target.value

    if (postcode.length > 1) {
      const request = new Request(`${baseUrl}/api/city-autocomplete/${postcode}`)

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(data => {
          if (!data.length) {
            this.dumpAutocomplete()

            return
          }

          this.dumpAutocomplete()

          this.fillAutocompleteList(data)
        })
    } else {
      this.dumpAutocomplete()
    }
  }

  keyboardNav() {
    let suggestions = []
    let currentFocus

    this.postcodeInput.addEventListener('keydown', (e) => {
      suggestions = this.autocompleteList.querySelectorAll('button')

      if (suggestions.length) {
        switch (e.code) {
          case 'ArrowDown':
            e.preventDefault()

            this.autocompleteList.querySelector('li:first-child button').focus()
            this.autocompleteList.querySelector('li:first-child button').parentNode.classList.add('active')
            this.autocompleteList.querySelector('li:first-child button').parentNode.setAttribute('aria-selected', 'true')
            currentFocus = 0
            break;
          case 'ArrowUp':
            e.preventDefault()

            this.autocompleteList.querySelector('li:last-child button').focus()
            this.autocompleteList.querySelector('li:last-child button').parentNode.classList.add('active')
            this.autocompleteList.querySelector('li:last-child button').parentNode.setAttribute('aria-selected', 'true')
            currentFocus = suggestions.length - 1
            break;
          case 'Escape':
            e.preventDefault()

            this.dumpAutocomplete()
          default:
            break;
        }
      }
    })

    this.autocompleteList.addEventListener('keydown', (e) => {
      if (suggestions.length) {
        if (document.activeElement.classList.contains('Form-autocompleteButton')) {
          switch (e.code) {
            case 'ArrowDown':
              e.preventDefault()

              suggestions.forEach(suggestion => {
                suggestion.parentNode.classList.remove('active')
                suggestion.parentNode.removeAttribute('aria-selected')
              })

              if (currentFocus < (suggestions.length - 1)) {
                currentFocus++
                suggestions[currentFocus].parentNode.classList.add('active')
                suggestions[currentFocus].parentNode.setAttribute('aria-selected', 'true')
                suggestions[currentFocus].focus()
              } else {
                currentFocus = 0
                suggestions[currentFocus].parentNode.classList.add('active')
                suggestions[currentFocus].parentNode.setAttribute('aria-selected', 'true')
                suggestions[currentFocus].focus()
              }
              break;
            case 'ArrowUp':
              e.preventDefault()

              suggestions.forEach(suggestion => {
                suggestion.parentNode.classList.remove('active')
                suggestion.parentNode.removeAttribute('aria-selected')
              })

              if (currentFocus > 0) {
                currentFocus--
                suggestions[currentFocus].parentNode.classList.add('active')
                suggestions[currentFocus].parentNode.setAttribute('aria-selected', 'true')
                suggestions[currentFocus].focus()
              } else {
                currentFocus = suggestions.length - 1
                suggestions[currentFocus].parentNode.classList.add('active')
                suggestions[currentFocus].parentNode.setAttribute('aria-selected', 'true')
                suggestions[currentFocus].focus()
              }
              break;
            case 'Escape':
              e.preventDefault()

              suggestions.forEach(suggestion => {
                suggestion.parentNode.classList.remove('active')
                suggestion.parentNode.removeAttribute('aria-selected')
              })

              this.postcodeInput.focus()
              this.dumpAutocomplete()
              break;
            case 'Tab':
              e.preventDefault()
              break;
            default:
              break;
          }
        }
      }
    })
  }

  listeners() {
    // Open autocomplete
    const openEvents = ['click', 'input']

    openEvents.forEach(event => {'click'
      this.postcodeInput.addEventListener(event, (e) => this.fetchCities(e))
    })

    // Close autocomplete on input blur
    document.addEventListener('click', () => {
      if (!document.activeElement.classList.contains('Form-autocomplete') || !document.activeElement.classList.contains('Form-autocompleteButton')) {
        this.dumpAutocomplete()
      }
    })
  }

  init() {
    if (!this.postcodeInput || !this.cityInput) return

    this.createAutocompleteList()
    this.addAccessibilityAttributes()
    this.keyboardNav()
    this.listeners()
  }
}
