<template>
  <a class="SPFPlayer-transcription" v-if="transcriptionUrl" :href="transcriptionUrl" target="_blank" rel="nofollow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor" d="M352.214 0H60.992v512h390.015V98.793L352.214 0zm47.575 90.002l-38.779-.005-.005-38.779 38.784 38.784zM90.992 482V30h240.011l.011 89.993 89.993.011V482H90.992z"/>
      <path fill="currentColor" d="M126 151.98h170v30H126zM126 211.98h259.01v30H126zM126 271.99h259.01v30H126zM126 331.99h259.01v30H126zM292.26 391.99H385v30h-92.74z"/>
    </svg>
    <span>{{ transcriptionLabel }}</span>
  </a>
  <!-- Original version -->
  <!-- <a class="SPFPlayer-transcription" v-if="transcriptionUrl" :href="transcriptionUrl" target="_blank" rel="nofollow">{{ transcriptionLabel }}</a> --> 
</template>

<script>
export default {
  name: 'Transcription',
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    originTranscriptionUrl: {
      required: false,
      type: String
    },
    transcriptionLabel: {
      required: false,
      type: String,
      default: 'Transcription'
    },
  },
  data() {
    return {
      transcriptionUrl: ''
    }
  },
  mounted() {
    this.transcriptionUrl = this.originTranscriptionUrl

    this.eventBus.$on('Player::UpdateMedia', ({ media } = payload) => {
      this.transcriptionUrl = media.transcription_url
    })
  },
}
</script>
